<template>
  <div v-if="loading_sources">
    <div class="preloader">
        <div class="preloader">
          <div class="logo"><img src="images/vetLogo.png" alt=""></div>
        </div>
    </div>
  </div>
  <div v-if="!loading_sources">
      <Header v-if="!mobileView" :categories="anotherCategories" :general="generalTexts" />
  <HeaderMobile v-if="mobileView" :categories="anotherCategories" :general="generalTexts"/>
  <div class="whats-floating">
    <a v-if="this.generalTexts[0]"
      :href="`https://api.whatsapp.com/send?phone=${this.generalTexts[0].whatsapp}&text=Olá, gostaria de saber mais sobre os produtos Vetgraf!`"
      target="_blank"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="50px"
        height="50px"
      >
        <path
          fill="#fff"
          d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
        />
        <path
          fill="#fff"
          d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
        />
        <path
          fill="#cfd8dc"
          d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
        />
        <path
          fill="#40c351"
          d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
        />
        <path
          fill="#fff"
          fill-rule="evenodd"
          d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
          clip-rule="evenodd"
        />
      </svg>
    </a>
  </div>
  <div class="popup" v-if="popup[0].active == 1">
    <div class="image">
      <img  v-if="this.popup[0]" :src="'https://cms.vetgraf.com.br/storage/'+this.popup[0].image" alt="">
      <div style="cursor: pointer" class="popup-close js-popup-close"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 30" width="30px" height="30px">
<g id="surface96612229">
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 7 4 C 6.742188 4 6.488281 4.097656 6.292969 4.292969 L 4.292969 6.292969 C 3.902344 6.683594 3.902344 7.316406 4.292969 7.707031 L 11.585938 15 L 4.292969 22.292969 C 3.902344 22.683594 3.902344 23.316406 4.292969 23.707031 L 6.292969 25.707031 C 6.683594 26.097656 7.316406 26.097656 7.707031 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.683594 26.097656 23.316406 26.097656 23.707031 25.707031 L 25.707031 23.707031 C 26.097656 23.316406 26.097656 22.683594 25.707031 22.292969 L 18.414062 15 L 25.707031 7.707031 C 26.097656 7.316406 26.097656 6.683594 25.707031 6.292969 L 23.707031 4.292969 C 23.316406 3.902344 22.683594 3.902344 22.292969 4.292969 L 15 11.585938 L 7.707031 4.292969 C 7.511719 4.097656 7.257812 4 7 4 Z M 7 4 "/>
</g>
</svg>
</div>
  </div>
  </div>
  <vueper-slides v-if="this.slides[0].background" autoplay :arrows="false">
    <!-- <template v-slot:arrow-left>
      <img src="images/arrow-right.png" alt="" />
    </template>
    <template v-slot:arrow-right>
      <img src="images/arrow-left.png" alt="" />
    </template> -->
    <vueper-slide v-for="(slide, i) in slides" :key="i" :duration="8000">
      <template v-slot:content>
        <section class="banner__container " style="overflow:hidden">
          <div class="banner__container__content">
            <div
              class="bg parallax"
              :style="{
                background:
                  'linear-gradient(180deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0) 43.44%),linear-gradient(180deg,rgba(242, 101, 34, 0) 0%,rgba(242, 101, 34, 0.5) 100%),url('+'https://cms.vetgraf.com.br/storage/' + slide.background +')',
              }"
            ></div>
            <div class="texts">
              <div class="title">
                <svg
                  width="46"
                  height="41"
                  viewBox="0 0 46 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M40.68 5.41209C39.6585 4.3688 38.4456 3.5412 37.1107 2.97655C35.7758 2.4119 34.345 2.12128 32.9 2.12128C31.455 2.12128 30.0242 2.4119 28.6893 2.97655C27.3544 3.5412 26.1415 4.3688 25.12 5.41209L23 7.57625L20.88 5.41209C18.8166 3.30572 16.0181 2.12237 13.1 2.12237C10.1819 2.12237 7.38338 3.30572 5.31999 5.41209C3.25661 7.51846 2.09741 10.3753 2.09741 13.3542C2.09741 16.333 3.25661 19.1899 5.31999 21.2963L7.43999 23.4604L23 39.3446L38.56 23.4604L40.68 21.2963C41.702 20.2535 42.5127 19.0153 43.0658 17.6526C43.6189 16.2899 43.9036 14.8292 43.9036 13.3542C43.9036 11.8791 43.6189 10.4185 43.0658 9.05574C42.5127 7.69301 41.702 6.45488 40.68 5.41209V5.41209Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span :style="{color:slide.color}">{{ slide.title }}</span>
              </div>
              <div class="desc">{{ slide.subtitle }}</div>
            </div>
          </div>
        </section>
      </template>
    </vueper-slide>
  </vueper-slides>
  <vueper-slides v-if="!this.slides[0].background" autoplay>
    <template v-slot:arrow-left>
      <img src="images/arrow-right.png" alt="" />
    </template>
    <template v-slot:arrow-right>
      <img src="images/arrow-left.png" alt="" />
    </template>
    <vueper-slide v-for="(slide, i) in slides" :key="i">
      <template v-slot:content>
        <section class="banner__container " style="overflow:hidden">
          <div class="banner__container__content">
            <div
              class="bg parallax"
              :style="{
                background:
                  'linear-gradient(180deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0) 43.44%),linear-gradient(180deg,rgba(242, 101, 34, 0) 0%,rgba(242, 101, 34, 0.5) 100%),url('+'https://cms.vetgraf.com.br/storage/' + slide.background +')',
              }"
            ></div>
            <div class="texts">
              <div class="title">
                <svg
                  width="46"
                  height="41"
                  viewBox="0 0 46 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M40.68 5.41209C39.6585 4.3688 38.4456 3.5412 37.1107 2.97655C35.7758 2.4119 34.345 2.12128 32.9 2.12128C31.455 2.12128 30.0242 2.4119 28.6893 2.97655C27.3544 3.5412 26.1415 4.3688 25.12 5.41209L23 7.57625L20.88 5.41209C18.8166 3.30572 16.0181 2.12237 13.1 2.12237C10.1819 2.12237 7.38338 3.30572 5.31999 5.41209C3.25661 7.51846 2.09741 10.3753 2.09741 13.3542C2.09741 16.333 3.25661 19.1899 5.31999 21.2963L7.43999 23.4604L23 39.3446L38.56 23.4604L40.68 21.2963C41.702 20.2535 42.5127 19.0153 43.0658 17.6526C43.6189 16.2899 43.9036 14.8292 43.9036 13.3542C43.9036 11.8791 43.6189 10.4185 43.0658 9.05574C42.5127 7.69301 41.702 6.45488 40.68 5.41209V5.41209Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span :style="{color:slide.color}">{{ slide.title }}</span>
              </div>
              <div class="desc">{{ slide.subtitle }}</div>
            </div>
          </div>
        </section>
      </template>
    </vueper-slide>
  </vueper-slides>
  <section
    id="carteiras-vacinacao"
    class="cards parallax-section"
    v-if="this.vacinacao[0]"
  >
    <div
      :style="{
        background: 'url('+'https://cms.vetgraf.com.br/storage/' + this.vacinacaoProdutosText[0].image + ')',
      }"
      class="bg"
    ></div>
    <div class="content">
      <div class="title" :style="{color:vacinacaoProdutosText[0].color}">{{ vacinacaoProdutosText[0].title }}</div>
      <div class="desc" :style="{color:vacinacaoProdutosText[0].color}">{{this.vacinacaoProdutosText[0].subtitle}}</div>
      <a href="#vacinacao-cards"
        ><svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 25.5L25.5 34L34 25.5"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25.5 17L25.5 34"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          /></svg
      ></a>
    </div>
  </section>
  <section id="vacinacao-cards" class="cards-items" v-if="vacinacaoProdutos[0]">
    <div class="grid">
      <div class="item">
        <div class="top">
          <img :src="vacinacaoProdutos[0].txtProdutoImagem" loading="lazy" alt="" />
        </div>
        <div class="bottom">
          <div class="inner">
            <div class="title">{{ vacinacaoProdutos[0].txtProdutoNome }}</div>
            <div v-html="vacinacaoProdutosText[0].price_one"></div>            
            <div class="btn">
              <Button
                :url="vacinacaoProdutos[0].txtProdutoUrl"
                text="Comprar"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="item grey">
        <div class="top">
          <img :src="vacinacaoProdutos[1].txtProdutoImagem" alt="" />
        </div>
        <div class="bottom">
          <div class="inner">
            <div class="title">{{ vacinacaoProdutos[1].txtProdutoNome }}</div>
            <div v-html="vacinacaoProdutosText[0].price_two"></div> 
            <div class="btn">
              <Button
                :url="vacinacaoProdutos[1].txtProdutoUrl"
                text="Comprar"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="top">
          <img :src="vacinacaoProdutos[2].txtProdutoImagem" alt="" />
        </div>
        <div class="bottom">
          <div class="inner">
            <div class="title">{{ vacinacaoProdutos[2].txtProdutoNome }}</div>
            <div v-html="vacinacaoProdutosText[0].price_three"></div> 
            <div class="btn">
              <Button
                :url="vacinacaoProdutos[2].txtProdutoUrl"
                text="Comprar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    id="receituarios"
    class="cards parallax-section"
    v-if="this.receituario[0]"
  >
    <div
      :style="{
        background: 'url('+'https://cms.vetgraf.com.br/storage/' + this.receituariosProdutosText[0].image + ')',
      }"
      class="bg"
    ></div>
    <div class="content">
      <div class="title" :style="{color:receituariosProdutosText[0].color}">{{ this.receituariosProdutosText[0].title }}</div>
      <div class="desc" :style="{color:receituariosProdutosText[0].color}">{{this.receituariosProdutosText[0].subtitle}}</div>
      <a href="#receituario-cards"
        ><svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 25.5L25.5 34L34 25.5"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25.5 17L25.5 34"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          /></svg
      ></a>
    </div>
  </section>
  <section
    id="receituario-cards"
    class="cards-items"
    v-if="receituariosProdutos[0]"
  >
    <div class="grid">
      <div class="item">
        <div class="top">
          <img :src="receituariosProdutos[0].txtProdutoImagem" loading="lazy" alt="" />
        </div>
        <div class="bottom">
          <div class="inner">
            <div class="title">
              {{ receituariosProdutos[0].txtProdutoNome }}
            </div>
            <div v-html="receituariosProdutosText[0].price_one"></div> 
            <div class="btn">
              <Button
                :url="receituariosProdutos[0].txtProdutoUrl"
                text="Comprar"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="item grey">
        <div class="top">
          <img :src="receituariosProdutos[1].txtProdutoImagem" alt="" />
        </div>
        <div class="bottom">
          <div class="inner">
            <div class="title">
              {{ receituariosProdutos[1].txtProdutoNome }}
            </div>
            <div v-html="receituariosProdutosText[0].price_two"></div>   
            <div class="btn">
              <Button
                :url="receituariosProdutos[1].txtProdutoUrl"
                text="Comprar"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="top">
          <img :src="receituariosProdutos[2].txtProdutoImagem" alt="" />
        </div>
        <div class="bottom">
          <div class="inner">
            <div class="title">
              {{ receituariosProdutos[2].txtProdutoNome }}
            </div>
            <div v-html="receituariosProdutosText[0].price_three"></div>  
            <div class="btn">
              <Button
                :url="receituariosProdutos[2].txtProdutoUrl"
                text="Comprar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    id="cartoes-veterinarios"
    class="cards parallax-section"
    v-if="this.cartoes[0]"
  >
    <div
      :style="{
        background: 'url('+'https://cms.vetgraf.com.br/storage/' + this.cartoesProdutosText[0].image + ')',
      }"
      class="bg"
    ></div>
    <div class="content">
      <div class="title" :style="{color:cartoesProdutosText[0].color}">{{ cartoesProdutosText[0].title }}</div>
      <div class="desc" :style="{color:cartoesProdutosText[0].color}">{{this.cartoesProdutosText[0].subtitle}}</div>
      <a href="#visitas-cards"
        ><svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 25.5L25.5 34L34 25.5"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25.5 17L25.5 34"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          /></svg
      ></a>
    </div>
  </section>
  <section id="visitas-cards" class="cards-items" v-if="cartoesProdutos[0]">
    <div class="grid">
      <div class="item">
        <div class="top">
          <img :src="cartoesProdutos[0].txtProdutoImagem" loading="lazy" alt="" />
        </div>
        <div class="bottom">
          <div class="inner">
            <div class="title">{{ cartoesProdutos[0].txtProdutoNome }}</div>
            <div v-html="cartoesProdutosText[0].price_one"></div> 
            <div class="btn">
              <Button :url="cartoesProdutos[0].txtProdutoUrl" text="Comprar" />
            </div>
          </div>
        </div>
      </div>
      <div class="item grey">
        <div class="top">
          <img :src="cartoesProdutos[1].txtProdutoImagem" alt="" />
        </div>
        <div class="bottom">
          <div class="inner">
            <div class="title">{{ cartoesProdutos[1].txtProdutoNome }}</div>
            <div v-html="cartoesProdutosText[0].price_two"></div> 
            <div class="btn">
              <Button :url="cartoesProdutos[1].txtProdutoUrl" text="Comprar" />
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="top">
          <img :src="cartoesProdutos[2].txtProdutoImagem" alt="" />
        </div>
        <div class="bottom">
          <div class="inner">
            <div class="title">{{ cartoesProdutos[2].txtProdutoNome }}</div>
            <div v-html="cartoesProdutosText[0].price_three"></div> 
            <div class="btn">
              <Button :url="cartoesProdutos[2].txtProdutoUrl" text="Comprar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="about-carousel">
    <div class="about-top" style="background: url(images/pets-bg.png)"></div>
    <div class="content" v-if="this.aboutTexts[0]">
      <div class="title" v-html="this.aboutTexts[0].pets_title"></div>
      <div class="desc" v-if="this.aboutTexts[0]">{{this.aboutTexts[0].pets_desc}}</div>
    </div>
    <div class="testimonials">
      <h2 class="title" v-if="this.testimonialsText[0]">{{ this.testimonialsText[0].title }}</h2>
      <div class="testimonials-grid" v-if="this.testimonialsItems.length">
       <vueper-slides
            autoplay
            class="no-shadow"
            :visible-slides="3"
            :breakpoints="{
              991: { visibleSlides: 1 },
              475: { visibleSlides: 1 },
            }"
          >
          <vueper-slide v-for="(testimonial, i) in testimonialsItems" :key="i" :duration="20000">
            <template v-slot:content>
              <div class="item">
                <p v-html=" testimonial.title"></p>
                <div class="image" :style="{background:'url('+'https://cms.vetgraf.com.br/storage/' + testimonial.image +')',
                }"></div>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>

        <div class="experience">
        <p v-if="this.testimonialsText[0]">{{ this.testimonialsText[0].desc }}</p>
          <a :href="this.testimonialsText[0].button_link" target="_blank" class="testimonial-btn" v-if="this.testimonialsText[0]">{{ this.testimonialsText[0].button_text }}
          <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="50px"
        height="50px"
      >
        <path
          fill="#fff"
          d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
        />
        <path
          fill="#fff"
          d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
        />
        <path
          fill="#cfd8dc"
          d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
        />
        <path
          fill="#40c351"
          d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
        />
        <path
          fill="#fff"
          fill-rule="evenodd"
          d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
          clip-rule="evenodd"
        />
      </svg>
          </a>
        </div>
      </div>
      
    </div>
    <div class="slider-container" v-if="!mobileView">
      <section id="visitas-cards" class="cards-items">
        <div class="grid">
          <vueper-slides
            :visible-slides="3"
            :breakpoints="{
              991: { visibleSlides: 2 },
              475: { visibleSlides: 1 },
            }"
          >
            <vueper-slide v-for="(cat, i) in categories" :key="i">
              <template v-slot:content>
                <div class="item grey">
                  <div class="top">
                    <img :src="cat.txtCategoriaThumb" loading="lazy" alt="" />
                  </div>
                  <div class="bottom">
                    <div class="inner">
                      <div class="title">
                        {{ cat.txtCategoriaNome }}
                      </div>
                      <Button
                        :url="
                          'https://vetgraf.com.br/vetgraf/produtos-categorias/' +
                            cat.txtCategoriaId +
                            '/' +
                            slugFy(cat.txtCategoriaNome) +
                            '.html'
                        "
                        text="Veja mais"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </section>
    </div>
    <div class="slider-mobile" v-if="mobileView">
      <section id="visitas-cards" class="cards-items">
        <div class="grid">
          <div class="item" v-for="(cat, i) in categories" :key="i">
            <div class="top">
              <img :src="cat.txtCategoriaThumb" alt="" />
            </div>
            <div class="bottom">
              <div class="inner">
                <div class="title">
                  {{ cat.txtCategoriaNome }}
                </div>
                <Button
                  :url="
                    'https://vetgraf.com.br/vetgraf/produtos-categorias/' +
                      cat.txtCategoriaId +
                      '/' +
                      slugFy(cat.txtCategoriaNome) +
                      '.html'
                  "
                  text="Veja mais"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
  <section id="about" class="parallax-section" v-if="this.aboutTexts[0]">
    <div class="text">{{this.aboutTexts[0].about_title}}</div>
    <div class="about-card">
      <svg
        class="heart"
        width="42"
        height="43"
        viewBox="0 0 42 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.47 8.25944C35.5761 7.3439 34.5149 6.61764 33.3468 6.12213C32.1788 5.62662 30.9268 5.37158 29.6625 5.37158C28.3981 5.37158 27.1461 5.62662 25.9781 6.12213C24.81 6.61764 23.7488 7.3439 22.855 8.25944L21 10.1586L19.145 8.25944C17.3395 6.41099 14.8908 5.37254 12.3375 5.37254C9.78416 5.37254 7.33542 6.41099 5.52996 8.25944C3.7245 10.1079 2.71021 12.6149 2.71021 15.229C2.71021 17.8431 3.7245 20.3502 5.52996 22.1986L7.38496 24.0978L21 38.0369L34.615 24.0978L36.47 22.1986C37.3642 21.2835 38.0736 20.197 38.5576 19.0011C39.0415 17.8052 39.2907 16.5235 39.2907 15.229C39.2907 13.9346 39.0415 12.6528 38.5576 11.4569C38.0736 10.2611 37.3642 9.17454 36.47 8.25944V8.25944Z"
          stroke="#FF7800"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.0002 21.2859C17.2779 21.2859 13 26.3488 13 30.5C13 32.3696 13.9517 33 16.0578 33C18.3471 33 19.1642 37.5 21.0002 37.5C22.8522 37.5 23.6735 33 25.9427 33C27.5 31 28.5 31.3696 28.5 29.5C28.5 25.3488 24.7225 21.2859 21.0002 21.2859ZM14.0966 20.6104C13.6091 18.7542 12.1072 17.5521 10.7422 17.9249C9.37723 18.2978 8.66614 20.1047 9.15364 21.9609C9.64113 23.8171 11.143 25.0193 12.508 24.6464C13.873 24.2736 14.5841 22.4666 14.0966 20.6104ZM18.0678 19.4972C19.5181 19.0611 20.2437 16.8219 19.6887 14.4959C19.1337 12.1698 17.5081 10.6382 16.0578 11.0743C14.6075 11.5104 13.8819 13.7496 14.4369 16.0757C14.9919 18.4017 16.6179 19.9338 18.0678 19.4972ZM31.2578 17.9254C29.8928 17.5526 28.3914 18.7547 27.9034 20.6109C27.4159 22.4672 28.127 24.2741 29.492 24.6469C30.857 25.0198 32.3584 23.8177 32.8464 21.9615C33.3339 20.1052 32.6228 18.2983 31.2578 17.9254ZM23.9327 19.4972C25.383 19.9333 27.0086 18.4017 27.5636 16.0757C28.1186 13.7496 27.393 11.5109 25.9427 11.0743C24.4924 10.6377 22.8668 12.1698 22.3118 14.4959C21.7568 16.8219 22.4824 19.0611 23.9327 19.4972Z"
          fill="#FF7800"
        />
      </svg>
      <div class="left">
        <img :src="`https://cms.vetgraf.com.br/storage/${this.aboutTexts[0].about_img}`" alt="mima" width="50%" />
        <div class="descWrapper">
          <div class="desc">{{this.aboutTexts[0].about_img_label}}</div>
          <div class="desc desc--orange">{{this.aboutTexts[0].about_img_label_destaque}}</div>
          <div class="desc desc--small" v-html="this.aboutTexts[0].about_img_label_obs ? this.aboutTexts[0].about_img_label_obs : '...'"></div>
        </div>
      </div>
      <div class="right" v-html="this.aboutTexts[0].about_right_text ? this.aboutTexts[0].about_right_text : '...'"></div>
    </div>
    <div style="background: url(images/paws-bg.png)" class="bg"></div>
  </section>
  <Footer :categories="categories" :general="generalTexts" :payments="this.paymentImgs" :security="this.securityImgs" />
  </div>
</template>

<script>
import Header from "./components/Header";
import HeaderMobile from "./components/HeaderMobile";
import Button from "./components/Button";
import Footer from "./components/Footer";
import $ from "jquery";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  name: "App",
  components: {
    Header,
    VueperSlides,
    VueperSlide,
    Button,
    Footer,
    HeaderMobile,
  },
  data: () => ({
    loading_sources: true,
    generalTexts: [],
    categories: [],
    anothercategories: [],
    vacinacao: [],
    vacinacaoProdutos: [],
    vacinacaoProdutosText: [],
    receituario: [],
    receituariosProdutos: [],
    receituariosProdutosText: [],
    cartoes: [],
    cartoesProdutos: [],
    cartoesProdutosText: [],
    aboutTexts : [],
    mobileView: false,
    paymentImgs: [],
    securityImgs: [],
    testimonialsItems: [],
    testimonialsText: [],
    popup: [],
  }),

  methods: {
    slugFy(string) {
      return (
        string
          .toString()
          .toLowerCase()
          .replace(/[àÀáÁâÂãäÄÅåª]+/g, "a") // Special Characters #1
          .replace(/[èÈéÉêÊëË]+/g, "e") // Special Characters #2
          .replace(/[ìÌíÍîÎïÏ]+/g, "i") // Special Characters #3
          .replace(/[òÒóÓôÔõÕöÖº]+/g, "o") // Special Characters #4
          .replace(/[ùÙúÚûÛüÜ]+/g, "u") // Special Characters #5
          .replace(/[ýÝÿŸ]+/g, "y") // Special Characters #6
          .replace(/[ñÑ]+/g, "n") // Special Characters #7
          .replace(/[çÇ]+/g, "c") // Special Characters #8
          .replace(/[ß]+/g, "ss") // Special Characters #9
          .replace(/[Ææ]+/g, "ae") // Special Characters #10
          .replace(/[Øøœ]+/g, "oe") // Special Characters #11
          .replace(/[%]+/g, "pct") // Special Characters #12
          .replace(/\s+/g, "-") // Replace spaces with -
          // .replace(/[^\w\-]+/g, "") // Remove all non-word chars
          // .replace(/\-\-+/g, "-") // Replace multiple - with single -
          .replace(/^-+/, "") // Trim - from start of text
          .replace(/-+$/, "")
      ); // Trim - from end of text
    },
    handleView() {
      this.mobileView = window.innerWidth <= 991;
    },

    /* async functions to fetch cms content */

    async fetchBanner() {
      return (await fetch("https://cms.vetgraf.com.br/api/banner")).json();
    },
    async fetchVaccinationWallet() {
      return (await fetch("https://cms.vetgraf.com.br/api/carteiras-de-vacinacao")).json();
    },
    async fetchVetPrescription() {
      return (await fetch("https://cms.vetgraf.com.br/api/receituarios-veterinarios")).json();
    },
    async fetchVetCards() {
      return (await fetch("https://cms.vetgraf.com.br/api/cartoes-veterinarios")).json();
    },  
    async fetchAboutTexts() {
      return (await fetch("https://cms.vetgraf.com.br/api/sobre")).json();
    },
    async fetchGeneralInfo() {
      return (await fetch("https://cms.vetgraf.com.br/api/informacoes-gerais")).json();
    },
    async fetchPaymentImg() {
      return (await fetch("https://cms.vetgraf.com.br/api/formas-de-pagamento")).json();
    },
    async fetchSecurityImg() {
      return (await fetch("https://cms.vetgraf.com.br/api/seguranca")).json();
    },
    async fetchTestimonials() {
      return (await fetch("https://cms.vetgraf.com.br/api/lista-de-comentarios")).json();
    },
     async fetchTestimonialsText() {
      return (await fetch("https://cms.vetgraf.com.br/api/lista-de-comentarios-textos")).json();
    },
     async fetchPopup() {
      return (await fetch("https://cms.vetgraf.com.br/api/popup-card")).json();
    },

    /* async functions to fetch cms content */


    async fetchCats() {
      const catsUrl =
        "https://vetgraf.com.br/vetgraf/jsons/categoriasLista.asp";
      const res = await fetch(catsUrl);
      let decoder = new TextDecoder("iso-8859-1");
      return JSON.parse(decoder.decode(await res.arrayBuffer()));
    },
    async fetchProducts() {
      const productsUrl =
        "https://vetgraf.com.br/vetgraf/jsons/produtosLista.asp";
      const res = await fetch(productsUrl);
      let decoder = new TextDecoder("iso-8859-1");
      return JSON.parse(decoder.decode(await res.arrayBuffer()));
    },

    async fetchProductsByCat(catID) {
      const productBycatUrl = `https://vetgraf.com.br/vetgraf/jsons/produtosLista.asp?cmbProdFiltroCat=${catID}`;
      const res = await fetch(productBycatUrl);
      let decoder = new TextDecoder("iso-8859-1");
      return JSON.parse(decoder.decode(await res.arrayBuffer()));
    },
  },
  async created() {
    /* CONTENT FROM THE CMS */

    //get slides banner
    const mainBannerSlides = await this.fetchBanner();
    this.slides = await mainBannerSlides;   

    //get vaccination wallet text
    const vaccinationWallet = await this.fetchVaccinationWallet();
    this.vacinacaoProdutosText = await vaccinationWallet;

    //get vet prescription text
    const vetPrescription = await this.fetchVetPrescription();
    this.receituariosProdutosText = await vetPrescription;

    //get vet cards text
    const vetCards = await this.fetchVetCards();
    this.cartoesProdutosText = await vetCards;

    //get about text
    const about = await this.fetchAboutTexts();
    this.aboutTexts = await about;

    //get general text
    const general = await this.fetchGeneralInfo();
    this.generalTexts = await general;

    //get payment imgs
    const payment = await this.fetchPaymentImg();
    this.paymentImgs = await payment;

     //get security imgs
    const security = await this.fetchSecurityImg();
    this.securityImgs = await security;

    //get testimonials card
    const testimonials = await this.fetchTestimonials();
    this.testimonialsItems = await testimonials;

    //get testimonials texts
    const testimonialsText = await this.fetchTestimonialsText();
    this.testimonialsText = await testimonialsText;

    this.loading_sources = false;

    //get Popup texts
    const popUp = await this.fetchPopup();
    this.popup = await popUp;
  
    /* CONTENT FROM THE CMS */

    // get cats
    const objCategorias = await this.fetchCats();
    this.categories = await objCategorias.objCategorias;
    this.vacinacao = await this.categories.filter((cat) => {
      return cat.txtCategoriaId == "0RMHQKNJ";
    });
    this.receituario = await this.categories.filter((cat) => {
      return cat.txtCategoriaId == "0OONQKNJ";
    });
    this.cartoes = await this.categories.filter((cat) => {
      return cat.txtCategoriaId == "8LQDRKNJ";
    });
    this.anotherCategories = await this.categories.filter((cat) => {
      return (
        cat.txtCategoriaId != "0RMHQKNJ" &&
        cat.txtCategoriaId != "0OONQKNJ" &&
        cat.txtCategoriaId != "8LQDRKNJ"
      );
    });

    const objVacinacao = await this.fetchProductsByCat("0RMHQKNJ");
    const vacinacaoProdutos = await objVacinacao.objProdutos;
    for (let i = 0; i < vacinacaoProdutos.length; i++) {
      const url = vacinacaoProdutos[i].txtProdutoImagem;
      const newUrl = url.replace("/Thumb_", "/IMG_");
      vacinacaoProdutos[i].txtProdutoImagem = newUrl;
    }
    this.vacinacaoProdutos = await vacinacaoProdutos.filter((item, index) => {
      if (index < 3) {
        return item;
      }
    });

    const objReceituarios = await this.fetchProductsByCat("0OONQKNJ");
    const receituariosProdutos = await objReceituarios.objProdutos;
    for (let i = 0; i < receituariosProdutos.length; i++) {
      const url = receituariosProdutos[i].txtProdutoImagem;
      const newUrl = url.replace("/Thumb_", "/IMG_");
      receituariosProdutos[i].txtProdutoImagem = newUrl;
    }
    this.receituariosProdutos = await receituariosProdutos.filter(
      (item, index) => {
        if (index < 3) {
          return item;
        }
      }
    );

    const objCartoes = await this.fetchProductsByCat("8LQDRKNJ");
    const cartoesProdutos = await objCartoes.objProdutos;
    for (let i = 0; i < cartoesProdutos.length; i++) {
      const url = cartoesProdutos[i].txtProdutoImagem;
      const newUrl = url.replace("/Thumb_", "/IMG_");
      cartoesProdutos[i].txtProdutoImagem = newUrl;
    }
    this.cartoesProdutos = await cartoesProdutos.filter((item, index) => {
      if (index < 3) {
        return item;
      }
    });
  },

  mounted() {

    this.handleView();
    window.addEventListener("resize", this.handleView);
    var winScrollTop = 0;
    $.fn.is_on_screen = function() {
      var win = $(window);
      var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft(),
      };
      viewport.bottom = viewport.top + win.height();
      var bounds = this.offset();
      bounds.bottom = bounds.top + this.outerHeight();
      return !(viewport.bottom < bounds.top || viewport.top > bounds.bottom);
    };

    const showModal = setInterval(() => {
      const modal = check_modal();
      if(modal != null){
        setTimeout(() => {
          modal.classList.add("active");
        }, 10000);
        clearInterval(showModal);
        const popupClose = document.querySelector(".js-popup-close");
        popupClose.addEventListener("click", function(){
          modal.classList.remove('active');
        })
      }
    }, 3000);


    function check_modal(){
      const popup = document.querySelector(".popup");
      return popup;
    }

    function parallax() {
      $(".parallax-section").each(function() {
        if ($(this).is_on_screen()) {
          var firstTop = $(this).offset().top;
          var $span = $(this).find(".bg");
          var moveTop = (firstTop - winScrollTop) * 0.3; //speed;
          $span.css("transform", "translateY(" + -moveTop + "px)");
        }
      });
    }
    $(window).scroll(function() {
      winScrollTop = $(this).scrollTop();
      parallax();
    });
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "phenomena";
  src: url("./fonts/phenomena-regular.woff") format("woff");
}
@font-face {
  font-family: "phenomena_bold";
  src: url("./fonts/phenomena-bold.woff") format("woff");
}
@font-face {
  font-family: "phenomena_light";
  src: url("./fonts/phenomena-light.woff") format("woff");
}
@font-face {
  font-family: "phenomena_black";
  src: url("./fonts/phenomena-black.woff") format("woff");
}
@import url("https://fonts.googleapis.com/css2?family=Palanquin&display=swap");
body,
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Palanquin", sans-serif;

  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
}
.preloader{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  background: rgb(255, 149, 0);
}
.preloader.hide{
  display: none;
}

.preloader .logo{
  width: 250px;
  height: 250px;
  position: relative;
  animation-name: example;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;  
}

@keyframes example {
  0%   {width: 250px; opacity: 0.5;}
  100% {width: 300px; opacity: 1;}
}

.preloader .logo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.whats-floating {
  position: fixed;
  bottom: 2%;
  right: 2%;
  z-index: 1000;
  transition: 0.3s all ease;
  &:hover {
    transform: scale(1.08);
  }
}

.popup{
  display: none;
  &.active{
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    .image{
      width: 420px;
      position: relative;
      margin-bottom: -90px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .popup-close{
        position: absolute;
        right: 5px;
        top: 5px;
        width: 35px;
        height: 35px;
        border: 2px solid #FFF;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.vueperslides__parallax-wrapper {
  height: 100vh !important;
}
.vueperslides__arrow--prev {
  left: 2%;
}
.vueperslides__arrow--next {
  right: 2%;
}
.vueperslides__bullets {
  bottom: 15%;
}
.banner {
  &__container {
    height: 100vh;
    &__content {
      height: 100vh;
      position: relative;
      .bg {
        height: 100vh;
        background-size: contain !important;
        background-blend-mode: normal, multiply, normal;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-position-x: 50% !important;
        background-position-y: 0%;
        -webkit-animation: zoomin 15s alternate;
        z-index: 0;
      }
      .texts {
        position: absolute;
        bottom: 30%;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
        width: 100%;
        .title {
          position: relative;
          z-index: 1;
          display: flex;
          justify-content: center;
          svg {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -25%;
            display: block;
            margin: auto;
            path {
              stroke-dasharray: 800;
              transition: all 2s cubic-bezier(0.47, 0, 0.745, 0.715);
              stroke: #fff;
              animation-name: deen;
              animation-timing-function: linear;
              animation-duration: 15s;
              animation-fill-mode: forwards;
            }
          }
          @keyframes deen {
            0% {
              stroke-dashoffset: 800;
            }
            100% {
              stroke-dashoffset: 0;
            }
          }
          span {
            font-style: normal;
            font-weight: bold;
            font-size: 135px;
            line-height: 180px;
            text-transform: uppercase;
            color: #ffffff;
            text-align: center;
            font-family: phenomena_black;
            animation-name: animateUp;
            opacity: 0;
            animation-timing-function: linear;
            animation-duration: 3s;
            animation-fill-mode: forwards;
            animation-delay: calc(1.5s * var(--i));
          }
          @keyframes animateUp {
            0%,
            100% {
              transform: translateY(0px);
            }
            20% {
              transform: translateY(-20px);
            }
            40% {
              transform: translateY(0px);
            }
            100% {
              opacity: 1;
            }
          }
        }
        .desc {
          z-index: 1;
          font-family: phenomena;
          font-style: normal;
          font-weight: normal;
          font-size: 40px;
          line-height: 89%;
          letter-spacing: 0.47em;
          text-transform: uppercase;
          color: #ffffff;
          text-align: center;
          filter: blur(0.5rem);
          animation-name: letterspacing;
          animation-timing-function: linear;
          animation-duration: 5s;
          animation-fill-mode: forwards;
        }
      }
    }
    @keyframes letterspacing {
      0% {
        letter-spacing: -0.47em;
        filter: blur(0.5rem);
      }

      50% {
        filter: blur(0.5rem);
      }

      100% {
        letter-spacing: 0.47em;
        filter: blur(0rem);
      }
    }
    @-webkit-keyframes zoomin {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: all 1.5s;
      }
      50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        transition: all 1.5s;
      }
      100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: all 1.5s;
      }
    }
  }
}
.cards {
  overflow: hidden;
  transition: 0.5s all ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 700px;
  padding-bottom: 5rem;
  background-size: cover !important;
  position: relative;

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 120%;
    z-index: 0;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
  }
  .content {
    z-index: 1;
    .title {
      font-family: phenomena_black;
      font-style: normal;
      font-weight: 900;
      font-size: 60px;
      line-height: 72px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      text-align: center;
      margin-bottom: 10px;
    }
    .desc {
      font-family: phenomena;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 89%;
      text-align: center;
      letter-spacing: 0.47em;
      text-transform: uppercase;
      color: #ffffff;
    }
    svg {
      text-align: center;
      display: block;
      margin: auto;
      margin-top: 50px;
    }
  }
}
.cards-items {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33.33%, 1fr));
    .item {
      display: flex;
      flex-direction: column;
      .top {
        overflow: hidden;
        z-index: 0;
        height: 50%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: 0.5s all ease;
          overflow: hidden;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
      .bottom {
        padding: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% / 2);
        .inner {
          .title {
            font-family: phenomena;
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 35px;
          }
          ul {
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 34px;
          }
        }
      }
    }
    .grey {
      background-color: #f8f8f8;
    }
  }
}
#about-carousel {
  overflow: hidden;
  .about-top {
    min-height: 50vh;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  .content {
    .title {
      font-family: phenomena;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 163%;
      text-align: center;
      letter-spacing: 0.47em;
      text-transform: uppercase;
      width: 80%;
      margin: 2rem auto;
      strong {
        font-family: phenomena;
        color: #f26522;
      }
    }
    .desc {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      color: #000000;
      text-align: center;
      margin-bottom: 50px;
    }
  }


  .testimonials{
    padding-bottom: 50px;
    .title{
      width: fit-content;
      margin: 50px auto;
      font-weight: 500;
    font-size: 30px;
    }
    .testimonials-grid{
      width: 90%;
      margin: 0 auto;
      .vueperslides__parallax-wrapper{
        height: 60vh !important;
        .vueperslides__track-inner{
          .vueperslide{
            height: fit-content;
            flex-wrap: wrap;
            height: 100%;
          }
        }
      }
       .vueperslides__arrows {
          display: none;
       }
      .vueperslides__bullet--active{
        .default {
          background-color: #f26522;
          border: none;
        }
      }
        

      .item{
        width: 95%;
        margin: 0 auto;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
          p{
            text-align: center;
            font-weight: bold;
          }
          .image{
            width: 100%;
            height: 500px;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            background-position: center !important;
          }
      }

      .experience{
        p{
              font-weight: 600;
              text-align: center;
              margin-bottom: 10px;
        }
        .testimonial-btn{
          max-width: 400px;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 33px;
          color: #ffffff;
          padding: 5px;
          background: #ff7800;
          border-radius: 9px;
          border: 1px solid transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          transition: 0.5s all ease;
          gap: 10px;
        }
      }
    }
  }

  .slider-container {
    width: 90%;
    margin: auto;
    margin-bottom: 150px;
    .vueperslides__parallax-wrapper {
      height: 715px !important;
    }
    .vueperslides__arrow--prev {
      left: 2%;
    }
    .vueperslides__arrow--next {
      right: 2%;
    }
    .vueperslides__bullets {
      bottom: -5%;
    }
    .vueperslides__arrows {
      display: none;
    }
    .vueperslides__bullet .default {
      height: 10px;
      width: 10px;
      border: 1px solid #000;
    }
    .vueperslides__bullet--active {
      .default {
        background-color: #f26522;
        border: none;
      }
    }
    .vueperslide:nth-child(even) {
      .item {
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1) !important;
        background-color: #fff !important;
      }
    }
    .grid {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      .top {
        max-height: 370px;
      }
      .item {
        height: 100%;
      }
    }
  }
}
#about {
  overflow: hidden;
  position: relative;
  .text {
    font-family: phenomena;
    font-style: normal;
    font-weight: normal;
    font-size: 80px;
    line-height: 88.5%;
    text-align: center;
    letter-spacing: 0.47em;
    text-transform: uppercase;
    color: #e3e3e3;
    max-width: 500px;
    margin: auto;
  }
  .about-card {
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 95%;
    max-width: 80%;
    margin: 160px auto 87px;
    position: relative;
    padding: 10rem 4rem 8rem;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: -15%;
      transform: translateX(-50%);
      height: 125px;
      border-right: 3px solid #ff7800;
    }
    display: flex;
    align-items: center;
  }
  .heart {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 15%;
  }
  .left {
    width: calc(45%);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 20px;
      width: 252px;
      height: 418px;
      top: -25%;
      position: absolute;
      object-fit: cover;
    }
    .descWrapper {
      margin-top: 155px;
      .desc {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        font-weight: bold;
        &--orange {
          font-size: 14px;
          line-height: 150%;
          color: #ff7800;
          font-weight: normal;
        }
        &--small {
          font-weight: normal;
          a {
            text-decoration: underline;
            color: #000000;
          }
          font-size: 12px;
          line-height: 150%;
        }
      }
    }
  }
  .right {
    width: calc(55%);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 29px;
    color: #000000;
  }
  .bg {
    position: absolute;
    left: 0;
    bottom: -100px;
    height: 60vh;
    width: 100%;
    z-index: -1;
  }
}
@media screen and (max-width: 991px) {
  .cards {
    min-height: 465px;
    .bg {
      background-size: 150% !important;
    }
  }

  .popup{
    .image{
      width: 380px;
      margin: 30px;
    }
  }

   .banner{
      &__container{
        &__content{
          .texts{
            .title{
              span{
                font-size: 120px;
              }
            }
            .desc{
              font-size: 30px;
              line-height: 115%;
            }
          }
        }
      }
    }

  .testimonials{
    min-height: 100vh;
    .title{
      text-align: center;
    }
    .experience{
      margin-top: 25px;
       .testimonial-btn{
        font-size: 13px !important;
        width: 290px;
    }
    }
    .vueperslides__bullets{
      bottom: -25px;
      .default{
        border: 1px solid #000;
      }
    }
  }
  .cards-items {
    .grid {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      .item {
        .top {
          height: 375px;
        }
      }
    }
  }
  #about-carousel {
    .about-top {
      min-height: 239px;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
    }
  }
}
@media screen and (max-width: 475px) {
  .vueperslides__arrow {
    top: 55%;
  }
  .banner {
    &__container {
      &__content {
        .bg {
          background-size: cover !important;
        }
      }
    }
  }
  .cards {
    max-height: 360px;
    min-height: auto;
    padding: 2rem;
    .bg {
      background-size: cover !important;
      background-position: center center !important;
      transform: translate(0) !important;
    }
    .content {
      .title {
        font-size: 44px;
        line-height: 100%;
      }
      .desc {
        font-size: 20px;
        line-height: 100%;
      }
      svg {
        margin-top: 15px;
      }
    }
  }
  #about-carousel {
    .about-top {
      min-height: 130px;
      background-size: cover !important;
    }
    .content {
      .title {
        font-size: 26px;
        width: 90%;
      }
    }
    .slider-container .vueperslides__bullets {
      bottom: -10%;
    }
  }
  #about {
    .text {
      font-size: 15vw;
      max-width: 95%;
    }
    .about-card {
      flex-direction: column;
      padding: 7rem 2rem 2rem;
      max-width: 90%;
      svg {
        top: 5%;
      }
      .left,
      .right {
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
      .left {
        .descWrapper {
          margin-top: 0;
        }
        img {
          width: 100%;
          height: auto;
          position: unset;
        }
      }
    }
  }
}
</style>
