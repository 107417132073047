<template>
  <header id="header" v-if="general[0]">
    <div class="left">
      <a href="/"><img :src="`https://cms.vetgraf.com.br/storage/${this.general[0].logo}`" alt="logo"/></a>
    </div>
    <div class="mid">
      <ul class="list">
        <li class="item">
          <a href="#receituarios" class="link">Receituários</a>
        </li>
        <li class="item">
          <a href="#carteiras-vacinacao" class="link">Carteiras de Vacinação</a>
        </li>
        <li class="item">
          <a href="#cartoes-veterinarios" class="link">Cartões Veterinários</a>
        </li>
        <li
          class="item open-cat"
          @click="show = !show"
          :class="{ active: show }"
        >
          <a class="link"
            >Outros produtos
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 12H21"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3 6H21"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3 18H21"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
          ></a>
          <ul>
            <li v-for="(cat, index) in categories" :key="index">
              <a
                :href="
                  'https://vetgraf.com.br/vetgraf/produtos-categorias/' +
                    cat.txtCategoriaId +
                    '/' +
                    slugFy(cat.txtCategoriaNome) +
                    '.html'
                "
                >{{ cat.txtCategoriaNome }}</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="right">
      <ul class="list">
        <li class="item">
          <a :href="`${general[0].login_link}`" class="link"
            >Login
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
          ></a>
        </li>
        <li class="item">
          <a
            :href="`${general[0].checkout_link}`"
            class="link"
            >Meu carrinho
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    show: false,
  }),
  props: ["categories","general"],
  methods: {
    slugFy(string) {
      return (
        string
          .toString()
          .toLowerCase()
          .replace(/[àÀáÁâÂãäÄÅåª]+/g, "a") // Special Characters #1
          .replace(/[èÈéÉêÊëË]+/g, "e") // Special Characters #2
          .replace(/[ìÌíÍîÎïÏ]+/g, "i") // Special Characters #3
          .replace(/[òÒóÓôÔõÕöÖº]+/g, "o") // Special Characters #4
          .replace(/[ùÙúÚûÛüÜ]+/g, "u") // Special Characters #5
          .replace(/[ýÝÿŸ]+/g, "y") // Special Characters #6
          .replace(/[ñÑ]+/g, "n") // Special Characters #7
          .replace(/[çÇ]+/g, "c") // Special Characters #8
          .replace(/[ß]+/g, "ss") // Special Characters #9
          .replace(/[Ææ]+/g, "ae") // Special Characters #10
          .replace(/[Øøœ]+/g, "oe") // Special Characters #11
          .replace(/[%]+/g, "pct") // Special Characters #12
          .replace(/\s+/g, "-") // Replace spaces with -
          // .replace(/[^\w\-]+/g, "") // Remove all non-word chars
          // .replace(/\-\-+/g, "-") // Replace multiple - with single -
          .replace(/^-+/, "") // Trim - from start of text
          .replace(/-+$/, "")
      ); // Trim - from end of text
    },
  },
};
</script>
<style lang="scss">
#header {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  width: 95%;
  transform: translateX(-50%);
  top: 0;
  padding: 3rem 0;
  .list {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      margin: 0 15px;
      .link {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 33px;
        color: #ffffff;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 0%;
          border-radius: 3px;
          border-top: 2px solid #fff;
          transition: 0.5s all ease;
          opacity: 0;
        }
        svg {
          margin-left: 15px;
        }
        &:hover {
          &::after {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
    .open-cat {
      position: relative;
      ul {
        position: absolute;
        top: 100%;
        left: 0%;
        transform: translateY(-100%);
        padding: 1rem 0rem 1rem 2rem;
        width: 300px;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        transition: 0.5s all ease;
        background: linear-gradient(
          to right bottom,
          rgba(0, 0, 0, 0.7),
          rgba(0, 0, 0, 0.3)
        );
        -webkit-backdrop-filter: blur(0.9rem);
        backdrop-filter: blur(0.9rem);
        border-radius: 5px;
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 180%;
          color: #ffffff;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: 0.5 all ease;
          position: relative;
          &::before {
            content: "";
            position: relative;
            display: inline-block;
            border-bottom: 1px solid #fff;
            width: 0;
            transition: 0.5s all ease;
          }
          &:hover {
            &::before {
              width: 15px;
              margin-right: 15px;
            }
          }
        }
      }
      &.active {
        ul {
          transform: translateY(0%);
          max-height: 999px;
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
  .mid {
    display: flex;
    flex: 1;
    .list {
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }
  .right {
    flex: 1;
    max-width: 315px;
    .list {
      .item {
        &:last-child {
          margin-right: 0 !important;
        }
      }
    }
  }
}
@media screen and(max-width:1366px) {
  #header {
    .list {
      .item {
        .link {
          font-size: 14px;
        }
      }
    }
    .mid {
      width: 58%;
      flex: unset;
    }
  }
}
</style>
