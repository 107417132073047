<template>
  <a :href="url" class="button"
    >{{ text }}
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5234 6.72289C28.7998 5.97769 27.9407 5.38654 26.9952 4.98322C26.0496 4.5799 25.0361 4.37231 24.0126 4.37231C22.9891 4.37231 21.9756 4.5799 21.03 4.98322C20.0844 5.38654 19.2253 5.97769 18.5018 6.72289L17.0001 8.26873L15.4984 6.72289C14.0369 5.21834 12.0546 4.37309 9.98759 4.37309C7.92062 4.37309 5.93832 5.21834 4.47675 6.72289C3.01519 8.22744 2.19409 10.2681 2.19409 12.3958C2.19409 14.5236 3.01519 16.5642 4.47675 18.0687L5.97842 19.6146L17.0001 30.9604L28.0218 19.6146L29.5234 18.0687C30.2473 17.3239 30.8216 16.4395 31.2134 15.4661C31.6052 14.4927 31.8068 13.4494 31.8068 12.3958C31.8068 11.3422 31.6052 10.2989 31.2134 9.3255C30.8216 8.35212 30.2473 7.46775 29.5234 6.72289V6.72289Z"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0001 17.3256C13.9868 17.3256 10.5237 21.4466 10.5237 24.8255C10.5237 26.3472 11.2942 26.8603 12.9991 26.8603C14.8524 26.8603 15.5138 30.5231 17.0001 30.5231C18.4994 30.5231 19.1642 26.8603 21.0011 26.8603C22.2618 25.2324 23.0714 25.5333 23.0714 24.0115C23.0714 20.6326 20.0134 17.3256 17.0001 17.3256ZM11.4114 16.7758C11.0168 15.2649 9.801 14.2864 8.69601 14.5899C7.59102 14.8934 7.01538 16.3642 7.41002 17.875C7.80466 19.3859 9.02045 20.3644 10.1254 20.0609C11.2304 19.7574 11.8061 18.2867 11.4114 16.7758ZM14.6262 15.8697C15.8003 15.5148 16.3877 13.6921 15.9384 11.7988C15.4891 9.90555 14.1731 8.65891 12.9991 9.01385C11.825 9.36879 11.2376 11.1914 11.6869 13.0847C12.1362 14.978 13.4525 16.2251 14.6262 15.8697ZM25.3039 14.5904C24.1989 14.2869 22.9834 15.2654 22.5884 16.7762C22.1938 18.2871 22.7694 19.7579 23.8744 20.0613C24.9794 20.3648 26.1948 19.3864 26.5898 17.8755C26.9845 16.3646 26.4088 14.8938 25.3039 14.5904ZM19.374 15.8697C20.5481 16.2246 21.864 14.978 22.3133 13.0847C22.7626 11.1914 22.1752 9.36922 21.0011 9.01385C19.8271 8.65848 18.5111 9.90555 18.0618 11.7988C17.6126 13.6921 18.2 15.5148 19.374 15.8697Z"
        fill="white"
      /></svg
  ></a>
</template>
<script>
export default {
  name: "Button",
  props: {
    url: String,
    text: String,
  },
};
</script>
<style lang="scss">
.button {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 33px;
  color: #ffffff;
  padding: 1rem;
  background: #ff7800;
  border-radius: 200px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  margin: auto;
  transition: 0.5s all ease;
  svg {
    margin-left: 15px;
    transition: 0.5s all ease;
  }
  &:hover {
    border: 3px solid #ff7800;
    background-color: transparent;
    color: #ff7800;
    svg {
      path {
        stroke: #ff7800;
      }
      path:nth-child(2) {
        fill: #ff7800;
      }
    }
  }
}
</style>