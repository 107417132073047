<template>
  <footer class="footer parallax-section" v-if="general[0]">
    <div style="background: url(images/paws-footer-bg.png)" class="bg"></div>
    <div class="inner">
      <div class="top">
        <div class="item">
          <div class="logo item"><img :src="`https://cms.vetgraf.com.br/storage/${general[0].logo_footer}`" alt="" /></div>
          <ul class="list">
            <li class="item">
              <a :href="`${general[0].login_link}`" class="link"
                >Sua Conta</a
              >
            </li>
            <li class="item">
              <a
                :href="`${general[0].checkout_link}`"
                class="link"
                >Carrinho</a
              >
            </li>
            <li class="item">
              <a
                :href="`${general[0].utiliza_link}`"
                class="link"
                >Quem já utiliza</a
              >
            </li>
            <li class="item">
              <a
                :href="`${general[0].porque_comprar_link}`"
                class="link"
                >Por que comprar conosco?</a
              >
            </li>
            <li class="item">
              <a
                :href="`${general[0].como_funciona_link}`"
                class="link"
                >Como funciona a personalização?</a
              >
            </li>
            <li class="item">
              <a
                :href="`${general[0].amor_medicina_link}`"
                class="link"
                >AMOR PELA MEDICINA VETERINÁRIA</a
              >
            </li>
          </ul>
        </div>
        <div class="item">
          <div class="title">Sobre a VETGRAF</div>
          <ul class="list">
            <li class="item">
              <a
                :href="`${general[0].quem_somos_link}`"
                class="link"
                >Quem somos
                <img :src="`https://cms.vetgraf.com.br/storage/${general[0].logo_edycopy}`" alt="" style="width:200px; display:block; margin:.5rem 0"
              />
                </a
              >
            </li>
            <li class="item">
              <a
                :href="`${general[0].historia_edicopy_link}`"
                class="link"
                >História da Edicopy</a
              >
            </li>
            <li class="item">
              <a
                :href="`${general[0].historia_mima_link}`"
                class="link"
                >História da Mima</a
              >
            </li>
            <li class="item">
              <a
                :href="`${general[0].como_pagar_link}`"
                class="link"
                >Como pagar faturado?</a
              >
            </li>
          </ul>
        </div>
        <div class="item">
          <div class="title">Central de Atendimento</div>
          <ul class="list">
            <li class="item" style="max-width: 250px">
              <a target="_blank" href="https://maps.google.com/?q=Rua Antoninho Darcy Campagnollo, 90 - Caxias do Sul" class="link" style="line-height: 20px">
                Edicopy Gráfica da Saúde<br />
                 {{general[0].address}}<br />
                {{general[0].phone}}<br />
                {{general[0].atendimento}}
              </a>
            </li>
            <li class="item" style="margin-top: 25px">
              <a :href="`mailto:${general[0].email}`" class="link"
                >{{general[0].email}}</a
              >
            </li>
            <li class="item atendimento" style="margin-top: 10px">
              <a
                target="_blank"
                class="link border"
                :href="`https://api.whatsapp.com/send?phone=${general[0].whatsapp}&text=Olá, gostaria de saber mais sobre os produtos Vetgraf!`"
                >FALE CONOSCO PELO WHATSAPP</a
              >
            </li>
          </ul>
        </div>
        <div class="item">
          <div class="title">Produtos</div>
          <ul class="list">
            <li v-for="(cat, index) in categories" :key="index" class="item">
              <a
                class="link"
                :href="
                  'https://vetgraf.com.br/vetgraf/produtos-categorias/' +
                    cat.txtCategoriaId +
                    '/' +
                    slugFy(cat.txtCategoriaNome) +
                    '.html'
                "
                >{{ cat.txtCategoriaNome }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <div class="item">
          <div class="title">Pagamento</div>
          <div class="payments">
            <img  v-for="(payment, index) in payments" :key="index" :src="`https://cms.vetgraf.com.br/storage/${payment.img}`" class="img-black" alt="" />
          </div>
        </div>
        <div class="item">
          <div class="title">Segurança</div>
          <div class="imgs">
            <img v-for="(sec, index) in security" :key="index" :src="`https://cms.vetgraf.com.br/storage/${sec.img}`" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="black" v-if="general[0]">
      <div class="item" v-html="general[0].footer_obs"></div>
      <div class="item social">
        <a :href="`${general[0].instagram}`" target="_blank"
          ><svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.6667 2.66699H9.33342C5.65152 2.66699 2.66675 5.65176 2.66675 9.33366V22.667C2.66675 26.3489 5.65152 29.3337 9.33342 29.3337H22.6667C26.3486 29.3337 29.3334 26.3489 29.3334 22.667V9.33366C29.3334 5.65176 26.3486 2.66699 22.6667 2.66699Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.3334 15.1594C21.4979 16.2691 21.3084 17.4024 20.7917 18.3981C20.275 19.3938 19.4575 20.2013 18.4555 20.7057C17.4535 21.21 16.3179 21.3856 15.2104 21.2073C14.1029 21.0291 13.0797 20.5062 12.2865 19.713C11.4932 18.9197 10.9703 17.8966 10.7921 16.789C10.6139 15.6815 10.7894 14.546 11.2938 13.5439C11.7981 12.5419 12.6056 11.7244 13.6013 11.2077C14.5971 10.6911 15.7304 10.5015 16.84 10.6661C17.9719 10.8339 19.0198 11.3614 19.8289 12.1705C20.6381 12.9796 21.1655 14.0275 21.3334 15.1594Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.3333 8.66699H23.3466"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            /></svg
        ></a>
        <a :href="`${general[0].facebook}`" target="_blank"
          ><svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.3998 2.66699H20.3998C18.6317 2.66699 16.936 3.36937 15.6858 4.61961C14.4355 5.86986 13.7332 7.56555 13.7332 9.33366V13.3337H9.73315V18.667H13.7332V29.3337H19.0665V18.667H23.0665L24.3998 13.3337H19.0665V9.33366C19.0665 8.98004 19.207 8.6409 19.457 8.39085C19.7071 8.1408 20.0462 8.00033 20.3998 8.00033H24.3998V2.66699Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: ["categories","general","payments","security"],
  methods: {
    slugFy(string) {
      return string
        .toString()
        .toLowerCase()
        .replace(/[àÀáÁâÂãäÄÅåª]+/g, "a") // Special Characters #1
        .replace(/[èÈéÉêÊëË]+/g, "e") // Special Characters #2
        .replace(/[ìÌíÍîÎïÏ]+/g, "i") // Special Characters #3
        .replace(/[òÒóÓôÔõÕöÖº]+/g, "o") // Special Characters #4
        .replace(/[ùÙúÚûÛüÜ]+/g, "u") // Special Characters #5
        .replace(/[ýÝÿŸ]+/g, "y") // Special Characters #6
        .replace(/[ñÑ]+/g, "n") // Special Characters #7
        .replace(/[çÇ]+/g, "c") // Special Characters #8
        .replace(/[ß]+/g, "ss") // Special Characters #9
        .replace(/[Ææ]+/g, "ae") // Special Characters #10
        .replace(/[Øøœ]+/g, "oe") // Special Characters #11
        .replace(/[%]+/g, "pct") // Special Characters #12
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    },
  },
};
</script>

<style lang="scss">
.footer {
  overflow: hidden;
  position: relative;
  background-color: #ff7800;
  .bg {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 100px;
    z-index: 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  .inner {
    padding: 5rem;
    .top {
      display: flex;
      align-items: flex-start;
      padding-top: 1rem;
      margin-bottom: 60px;
      .item {
        width: 100%;
        .title {
          font-family: phenomena;
          font-style: normal;
          font-size: 20px;
          line-height: 24px;
          text-transform: uppercase;
          color: #ffffff;
          margin-bottom: 20px;
        }
        .list {
          .item {
            .link {
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 271.04%;
              color: #ffffff;
            }
          }
          .border {
            padding: 5px 15px;
            border: 2px solid #fff;
            border-radius: 9999px;
            transition: 0.3s all;
            &:hover {
              background: #fff;
              color: #ff7800;
            }
          }
          .edigraf-logo {
            width: 200px;
            margin-top: 10px;
            a {
              width: 100%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 31px;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .img-black {
        mix-blend-mode: lighten;
      }
      .imgs {
        display: flex;
        align-items: center;
      }
      .item:first-child {
        .payments {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          img {
            margin-right: 15px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .black {
    display: flex;
    background-color: #282828;
    align-items: center;
    padding: 3rem 5rem;
    justify-content: space-between;
    .item {
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 143.5%;
      color: #919191;
      width: 100%;
    }
    .social {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        margin: 0 20px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .footer {
    .inner {
      .bottom {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .bg {
      top: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .footer {
    .inner {
      padding: 2rem;
      .top {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .item {
          margin: 1rem;
          width: auto;
          text-align: center;
          ul {
            .item {
              margin: 0;
            }
          }
        }
      }
      .bottom {
        justify-content: center;
        align-items: center;
        .item:first-child .payments {
          flex-direction: column;
        }
        .title {
          text-align: center;
        }
      }
    }
    .bg {
      top: 0;
    }
    .black {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      text-align: center;
      .social {
        margin-top: 1rem;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
