<template>
  <header id="header-mobile" :class="{ active: showMenu }" v-if="general[0]">
    <div class="left">
      <a href="/"><img :src="`https://cms.vetgraf.com.br/storage/${this.general[0].logo}`" alt="logo"/></a>
    </div>
    <div class="right">
      <div @click="showMenu = !showMenu" :class="{ active: showMenu }" id="btn">
        <div id="top"></div>
        <div id="middle"></div>
        <div id="bottom"></div>
      </div>
      <div id="box" :class="{ active: showMenu }">
        <ul id="items">
          <li class="item">
            <a href="#receituarios" class="link">Receituários</a>
          </li>
          <li class="item">
            <a href="#carteiras-vacinacao" class="link"
              >Carteiras de Vacinação</a
            >
          </li>
          <li class="item">
            <a href="#cartoes-veterinarios" class="link">Cartões Veterinários</a>
          </li>
          <li class="item" @click="show = !show">
            <a class="link"
              >Outros produtos
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 12H21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 6H21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 18H21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                /></svg
            ></a>
            <ul class="another_cats" :class="{ show: show }">
              <li v-for="(cat, index) in categories" :key="index">
                <a
                  :href="
                    'https://vetgraf.com.br/vetgraf/produtos-categorias/' +
                      cat.txtCategoriaId +
                      '/' +
                      slugFy(cat.txtCategoriaNome) +
                      '.html'
                  "
                  >{{ cat.txtCategoriaNome }}</a
                >
              </li>
            </ul>
          </li>
          <li class="item">
            <a :href="`${general[0].login_link}`" class="link"
              >Login<svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                /></svg
            ></a>
          </li>
          <li class="item">
            <a
              :href="`${general[0].checkout_link}`"
              class="link"
              >Meu Carrinho<svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                /></svg
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderMobile",
  data: () => ({
    showMenu: false,
    show: false,
  }),
  props: ["categories","general"],
  methods: {
    slugFy(string) {
      return (
        string
          .toString()
          .toLowerCase()
          .replace(/[àÀáÁâÂãäÄÅåª]+/g, "a") // Special Characters #1
          .replace(/[èÈéÉêÊëË]+/g, "e") // Special Characters #2
          .replace(/[ìÌíÍîÎïÏ]+/g, "i") // Special Characters #3
          .replace(/[òÒóÓôÔõÕöÖº]+/g, "o") // Special Characters #4
          .replace(/[ùÙúÚûÛüÜ]+/g, "u") // Special Characters #5
          .replace(/[ýÝÿŸ]+/g, "y") // Special Characters #6
          .replace(/[ñÑ]+/g, "n") // Special Characters #7
          .replace(/[çÇ]+/g, "c") // Special Characters #8
          .replace(/[ß]+/g, "ss") // Special Characters #9
          .replace(/[Ææ]+/g, "ae") // Special Characters #10
          .replace(/[Øøœ]+/g, "oe") // Special Characters #11
          .replace(/[%]+/g, "pct") // Special Characters #12
          .replace(/\s+/g, "-") // Replace spaces with -
          // .replace(/[^\w\-]+/g, "") // Remove all non-word chars
          // .replace(/\-\-+/g, "-") // Replace multiple - with single -
          .replace(/^-+/, "") // Trim - from start of text
          .replace(/-+$/, "")
      ); // Trim - from end of text
    },
  },
};
</script>

<style lang="scss">
#header-mobile {
  overflow: hidden;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
  top: 0;
  padding: 3rem 0;
  &.active {
    overflow: unset;
  }
  #btn {
    position: fixed;
    z-index: 5;
    top: 65px;
    right: 15px;
    cursor: pointer;
    transition: right 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
    div {
      width: 35px;
      height: 2px;
      margin-bottom: 8px;
      background-color: #fff;
      transition: transform 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91),
        opacity 500ms, background-color 250ms;
    }
    &.active {
      right: 300px;
      div {
        background-color: #ff7800;
      }
      #top {
        transform: translateY(10px) rotate(-135deg);
      }
      #middle {
        opacity: 0;
        transform: rotate(135deg);
      }
      #bottom {
        transform: translateY(-10px) rotate(-45deg);
      }
    }
  }

  #box {
    border-radius: 5px;
    position: absolute;
    z-index: 4;
    top: 45px;
    right: -275px;
    width: 340px;
    opacity: 0;
    padding: 20px 0px;
    background-color: #fff;
    color: #000;
    transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
    box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.3)
    );
    backdrop-filter: blur(0.9rem);
    &.active {
      right: 0px;
      opacity: 1;
    }
  }
  #items {
    padding-top: 25px;
    .item {
      position: relative;
      cursor: pointer;
      font-size: 2em;
      padding: 0 15px;
      transition: all 250ms;
      .link {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #000;
        display: flex;
        align-items: center;
        padding: 10px 0;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0px;
          width: 0%;
          border-radius: 3px;
          border-top: 2px solid #ff7800;
          transition: 0.5s all ease;
          opacity: 0;
        }
        svg {
          margin-left: 15px;
          path {
            stroke: #000;
          }
        }
        &:hover {
          &::after {
            width: 60%;
            opacity: 1;
          }
        }
      }
      .another_cats {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        pointer-events: none;
        transition: 0.5s all ease-in-out;
        &.show {
          max-height: 999px;
          opacity: 1;
          pointer-events: all;
        }
        a {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          color: #000;
          display: flex;
          align-items: center;
          padding: 5px 0;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0px;
            width: 0%;
            border-radius: 3px;
            border-top: 1px solid #ff7800;
            transition: 0.5s all ease;
            opacity: 0;
          }
          &:hover {
            &::after {
              width: 60%;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  #btn,
  #btn * {
    will-change: transform;
  }
  #box {
    will-change: transform, opacity;
  }
}
</style>
